body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 1.5;
    max-width: 1200px;
    margin: 0 auto; /* Wycentrowanie treści */
}

.container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    color: #343a40;
}

label {
    display: block;
    margin-top: 10px;
    color: #495057;
}

input, select {
    width: 90%;
    margin-right:5px;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.header, .section {
    margin-bottom: 20px;
}

.header h1 {
    font-size: 2.5em;
    color: #333;
}

.section h2 {
    font-size: 2em;
    color: #666;
    text-align: left;
}

.section p {
    font-size: 1.2em;
    color: #555;
    text-align: left;
}

.cta {
    background: #007BFF;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    font-size: 1.2em;
}

.cta:hover {
    background: #0056b3;
}

/* CSS dla urządzeń mobilnych */
@media (max-width: 600px) {
    .container {
        width: 90%;
        margin: 10px auto; /* Zmiana marginesów na auto dla wycentrowania */
        padding: 10px;
    }
    .header h1 {
        font-size: 2em;
    }
    .section h2 {
        font-size: 1.5em;
    }
    .section p {
        font-size: 1em;
    }
    .cta {
        padding: 10px 20px;
        font-size: 1em;
    }
    body{
        margin-left: 5px;
        margin-right: 5px;
        padding:2px;
    }
}



footer {
    border-top: 8px solid #d4d4d4;
    padding: 2rem 1rem;
    color: #757575;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .footer__row__2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .footer__row__1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .footer__row__3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .footer__row__4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .footer__row__1 > h4 {
    font-size: 15px;
    font-weight: 500;
  }
  
  .footer__row__3 > .dropdown__container {
    width: fit-content;
    border: 1px solid #757575;
    color: #757575;
  }
  
  .footer__row__3 > .dropdown__container > .language__drop__down {
    color: #757575;
  }

  /* Styl dla linków w stopce */
footer a {
    color: #007bff; /* Kolor tekstu linku */
    text-decoration: none; /* Usunięcie podkreślenia */
    font-weight: 400; /* Waga czcionki */
    transition: color 0.3s ease, text-decoration 0.3s ease; /* Płynne przejście kolorów i podkreślenia */
}
li {
    text-align: left;
}
footer a:hover {
    color: #0056b3; /* Kolor tekstu linku po najechaniu */
    text-decoration: underline; /* Podkreślenie linku po najechaniu */
}

footer a:active {
    color: #003d7a; /* Kolor tekstu linku podczas kliknięcia */
}

footer a:visited {
    color: #6c757d; /* Kolor tekstu linku po odwiedzeniu */
}

/* Opcjonalnie: stylizacja dla linków w kontekście innych elementów stopki */
footer a {
    padding: 0.5rem; /* Odstęp wewnętrzny */
    display: inline-block; /* Wyświetlanie jako element blokowy w linii */
}

        /* Styl podstawowy przycisku */
        .loading-button {
            position: relative; /* Ustawia kontekst dla położenia spinnera */
            background-color: #007bff; /* Kolor tła przycisku */
            color: #fff; /* Kolor tekstu przycisku */
            border: none; /* Brak obramowania */
            border-radius: 4px; /* Zaokrąglenie rogów */
            padding: 10px 10px; /* Odstępy wewnętrzne */
            font-size: 16px; /* Rozmiar czcionki */
            cursor: pointer; /* Wskaźnik kursora */
            outline: none; /* Usunięcie konturu po kliknięciu */
            overflow: hidden; /* Ukrywa spinner, gdy nie jest potrzebny */
            text-align: center; /* Wyrównanie tekstu do środka */
        }

        /* Styl dla tekstu przycisku */
        .button-text {
            display: inline-block; /* Ustawienie tekstu w linii */
        }

        /* Styl dla spinnera */
        .spinner {
            position: absolute; /* Ustawienie spinnera w stosunku do przycisku */
            left: 50%; /* Położenie w poziomie w środku */
            transform: translate(-50%, -50%); /* Wyśrodkowanie spinnera */
            width: 14px; /* Szerokość spinnera */
            height: 14px; /* Wysokość spinnera */
            border: 3px solid rgba(255, 255, 255, 0.3); /* Kolor tła spinnera */
            border-top: 3px solid #fff; /* Kolor górnej krawędzi spinnera */
            border-radius: 50%; /* Okrogłe kształt spinnera */
            animation: spin 1s linear infinite; /* Animacja obracania */
            display: none; /* Spinner domyślnie ukryty */
        }

        /* Animacja obracania spinnera */
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        /* Styl dla przycisku w stanie ładowania */
        .loading-button.loading .spinner {
            display: inline-block; /* Spinner widoczny w stanie ładowania */
        }

        .loading-button.loading .button-text {
            visibility: hidden; /* Ukrywa tekst, gdy spinner jest widoczny */
        }

        /* Styl dla treści strony */
        .content {
            padding: 20px;
        }

        .error-message {
            color: red;
            font-weight: bold;
            margin-top: 10px;
        }
		
.feature-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Odstępy między kolumnami */
}

.feature {
    flex: 1 1 30%; /* Trzy kolumny */
    box-sizing: border-box;
    text-align: center;
}

.feature img {
    max-width: 50px;
    height: auto;
}

.feature h3 {
    margin: 10px 0;
}

.feature p {
    margin: 0;
}

@media (max-width: 768px) {
    .feature {
        flex: 1 1 100%; /* Jedna kolumna na urządzeniach mobilnych */
    }
}

.testimonials {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
}

.testimonial {
    background-color: #f9f9f9;
    border-left: 5px solid #007bff;
    padding: 20px;
    border-radius: 5px;
}

.testimonial blockquote {
    margin: 0;
    font-style: italic;
}

.testimonial .author {
    margin-top: 10px;
    font-weight: bold;
    text-align: right;
    color: #333;
}
